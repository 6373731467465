<template lang="html">
  <div class="wrapper">
    <va-navibar :hasMenu="false"></va-navibar>
    <div class="p-3">
      <h3 class="my-3">{{(data||{}).title}}</h3>
      <p>{{(data||{}).text}}</p>
      <hr>
      <h4 class="mt-3">购买时长</h4>
      <div class="btn-group">
        <label class="btn btn-default text-center" @click="quantity=quantity>2?quantity-1:1;">
          <!--<input type="button" name="color_option" autocomplete="off">-->
          <span class="text-lg">
            <i class="fas fa-minus mr-2"></i>
          </span>
        </label>
        <label class="btn btn-default text-center">
          <!--<input type="number" name="color_option" autocomplete="off">-->
          <span class="text-lg">{{quantity}}年</span>
        </label>
        <label class="btn btn-default text-center" @click="quantity=quantity<10?quantity+1:10;">
          <!--<input type="button" name="color_option" autocomplete="off">-->
          <span class="text-lg">
            <i class="fas fa-plus mr-2"></i>
          </span>
        </label>
      </div>
      <div class="bg-gray py-2 px-3 mt-4">
        <h4 class="mt-0">
          <small v-if="data.originalPrice>data.price" style="color:#000000"><strike>￥{{data.originalPrice}}/年</strike><br /></small>
          <small> ￥{{data.price}}/年 </small><small></small>
        </h4>
        <h3 class="mb-0">
          合计：￥{{data.price * quantity}}
        </h3>
      </div>
      <div class="mt-4 text-right">
        <button class="btn btn-primary btn-flat" @click="buy()" :disabled="loading">
          确认订单
        </button>
        <!--<div class="btn btn-default btn-lg btn-flat" @click="buy()" style="background-color: #2BA245;color:#fff;">
            <i class="fab fa-weixin fa-lg mr-2"></i>
            微信支付
        </div>
        <div class="btn btn-default btn-lg btn-flat" @click="buyQrcode()" style="background-color: #1AAD19;color: #fff;">
            <i class="fas fa-qrcode fa-lg mr-2"></i>
            扫码支付
        </div>
        <div class="btn btn-default btn-lg btn-flat" @click="orderQuery(order.orderNo)" style="background-color: #EA6853;color: #fff;">
            查询
        </div>-->
      </div>
    </div>
    <SimpleModal ref="share" @Hide="onHide" :zIndex="1040">
      <div v-show="$refs.share&&$refs.share.show" class="panoinfo">
        <img v-if="url" :src="url" class="img-fluid">
        <div class="text-center">
          手机微信扫描支付
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
  import { SimpleModal } from 'common'
  //import SimpleModal from '@/components/SimpleModal.vue'
  import VANaviBar from '@/components/NaviBar.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      'va-navibar': VANaviBar,
      SimpleModal
    },
    data() {
      return {
        data: {
          productId: null,
          title: null,
          text: null,
          price: 0
        },
        quantity: 1,
        order: null,
        query: null,
        paid: false,
        info: null,
        url: null,
        loading: false,
        checkPay: null,
      }
    },
    created() {
      //this.getUser()
      //this.setPageInfo("主页")
      //this.loadData()
      this.loadProduct()
    },
    mounted() {
    },
    computed: {
      ...mapGetters([
        'setting',
      ])
    },
    methods: {
      loadPayInfo(orderNo) {
        this.loading = true;
        var config = {
          params: {
            orderNo: orderNo,
          }
        };
        return this.$axios.get('/Api/Weixin/Pay', config).then((res) => {
          //console.log(res.data)
          this.info = res.data
          this.loading = false;
        }).catch((e) => {
          this.loading = false;
        })

      },
      loadProduct() {
        this.loading = true;
        var config = {
          params: {
            //id: 40002,
            id: this.$route.params.productId,
          }
        };
        return this.$axios.get('/Api/My/Products', config).then((res) => {
          //console.log(res.data)
          this.data = res.data
          this.loading = false;
        }).catch((e) => {
          this.$message({
            message: e,
            type: 'error',
            zIndex: 9999,
          })
          // this.loading = false;
        })
      },
      buyQrcode() {
        this.loading = true;
        var config = {
          params: {
            articleId: this.$route.query.id,
            quantity: this.quantity,
          }
        };
        return this.$axios.get('/Api/My/Products/Buy', config).then((res) => {
          //console.log(res.data)
          this.order = res.data
          this.url = `${window.global.API_BASE_URL}/Api/Weixin/Pay/QRCode?orderNo=${this.order.orderNo}`
          this.$refs.share.show = true
          this.loading = false;
          var func = () => {
            this.$refs.share.show = false
          }
          if (this.checkPay) {
            clearInterval(this.checkPay)
            this.checkPay = null
          }
          this.checkPay = setInterval(() => {
            this.orderQuery(this.order.orderNo, func)
          }, 1000)
        }).catch(e => {
          this.loading = false;
        })
      },
      onHide() {
        if (this.checkPay) {
          clearInterval(this.checkPay)
          this.checkPay = null
        }
      },
      buy() {
        this.loading = true;
        console.log()
        var config = {
          params: {
            articleId: this.$route.query.id,
            quantity: this.quantity,
            productId: this.data.id,
          }
        };
        return this.$axios.get('/Api/My/Products/Buy', config).then((res) => {
          console.log(res.data)
          this.loading = false;
          this.order = res.data.data
          if (res.data.succeeded) {
            location.href = `${this.setting.payUrl}/Pay/?orderNo=${this.order.orderNo}&returnUrl=${this.getReturnUrl()}`
          } else {
            this.$message({
              message: res.data.errorMessage,
              type: 'info',
              zIndex: 9999,
            })
            if (res.data.errorMessage == '还有未支付订单！') {
              this.$router.push({ name: 'OrderList', params: {} })
            }
          }
          //console.log(`${this.setting.payUrl}/Pay/?orderId=${this.order.id}`, this.order)
          //this.pay(this.order.orderNo)
        }).catch(e => {
          console.log(e);
          this.loading = false;
          this.$message({
            message: e,
            type: 'error',
            zIndex: 9999,
          })
        })
      },
      getReturnUrl() {
        var type = this.data.type
        switch (type) {
          case '全景年费':
            return `${this.setting.myUrl}/Pano`
          case '漫游年费':
            return `${this.setting.myUrl}/Tour`
          case '博文年费':
            return `${this.setting.myUrl}/Blog`
          default:
        }
      },
      pay(orderNo) {
        if (typeof WeixinJSBridge == "undefined") {//微信浏览器内置对象。参考微信官方文档
          //if (document.addEventListener) {
          //    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(orderNo), false);
          //} else if (document.attachEvent) {
          //    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(orderNo));
          //    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(orderNo));
          //}
          this.url = `${window.global.API_BASE_URL}/Api/Weixin/Pay/QRCode?orderNo=${orderNo}`
          this.$refs.share.show = true
        } else {
          this.onBridgeReady(orderNo);
        }
      },
      orderQuery(orderNo, func) {
        this.loading = true;
        var config = {
          params: {
            orderNo: orderNo,
          }
        };
        return this.$axios.get('/Api/Weixin/Pay/OrderQuery', config).then((res) => {
          //console.log(res.data)
          this.query = res.data
          if (res.data.trade_state == 'SUCCESS') {
            this.paid = true
            if (func) {
              func()
            }
          }
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        })
      },
      onBridgeReady: function (orderNo) {
        this.loadPayInfo(orderNo)
          .then(() => {
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
              debug: true,
              "appId": this.info.appId,//公众号名称，由商户传入
              "timeStamp": this.info.timeStamp,//时间戳
              "nonceStr": this.info.nonceStr,//随机串
              "package": this.info.package,//预支付id
              "signType": this.info.signType,//微信签名方式
              "paySign": this.info.paySign,//微信签名
            }, (res) => {
              this.orderQuery(orderNo)
              console.log(res.err_msg)
              //debugger;
              //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回 ok，但并不保证它绝对可靠。
              switch (res.err_msg) {
                case "get_brand_wcpay_request:ok": //支付成功
                  console.log('支付成功')
                  // that.$router.push({path:'/SettlemenSuccess'})
                  break;
                case "get_brand_wcpay_request:cancel": //支付取消
                  console.log('取消支付')
                  break;
                case "get_brand_wcpay_request:fail": //支付失败
                  console.log('支付失败')
                  break;
                default:
                  // console.log(res.err_msg);
                  break;
              }
            })
          })
      },
    },
  }
</script>

<style lang="css">
</style>
